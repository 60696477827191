function ButtonText({ btnLabel, onClick, btnClassName, children, type }) {
    return (
        <button className={`button ${btnClassName}`} onClick={onClick} type={type}>
            <span className="btn__text">{btnLabel}</span>
            <span className="btn__text">{children}</span>
        </button>
    );
}
export default ButtonText;

