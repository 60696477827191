import { Link } from "react-router-dom";
import Menu from "./Menu";

function Header() {
    return (
        <header className="header">
            <div className="wrapper header-wrapper">
                <Link to="/">
                    <h2 className="logo">Equipment manager</h2>
                </Link>
                <Menu />
                <div className="header-icons">
                    <div className="icon__circle icon__profile-name">
                        <span className="profile-name">J</span>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;

