import { NavLink } from "react-router-dom";

function Menu() {
    return (
        <nav>
            <ul className="menu__list">
                <li className="menu__list-item">
                    <NavLink
                        to="/users"
                        className={({ isActive }) =>
                            isActive ? "menu__list-link menu__list-link_active" : "menu__list-link "
                        }
                    >
                        Users
                    </NavLink>
                </li>

                <li className="menu__list-item">
                    <NavLink
                        to="/equipment_glossary"
                        className={({ isActive }) =>
                            isActive ? "menu__list-link menu__list-link_active" : "menu__list-link "
                        }
                    >
                        Equipment glossary
                    </NavLink>
                </li>
                <li className="menu__list-item">
                    <NavLink
                        to="/documents"
                        className={({ isActive }) =>
                            isActive ? "menu__list-link menu__list-link_active" : "menu__list-link "
                        }
                    >
                        Documents
                    </NavLink>
                </li>

                <li className="menu__list-item">
                    <NavLink
                        to="/reports"
                        className={({ isActive }) =>
                            isActive ? "menu__list-link menu__list-link_active" : "menu__list-link "
                        }
                    >
                        Reports
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
export default Menu;

