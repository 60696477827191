// const NotFound = () => {
//     return <>NotFound</>;
// };

// export default NotFound;

import React, { Component } from "react";

export default class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState((prev) => ({
            counter: prev.counter + 1,
        }));
    }

    // handleClick = () => {
    //     this.setState(() => ({
    //         counter: this.state.counter + 1,
    //     }));
    // };

    render() {
        return (
            <>
                <div>{this.state.counter}</div>
                <button onClick={this.handleClick}>Click</button>
            </>
        );
    }
}

