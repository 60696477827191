function Button({ btnLabel, btnClassName, onClick, children, ...restProps }) {
    return (
        <button className={`btn ${btnClassName}`} onClick={onClick} {...restProps}>
            <span className="btn__text">{btnLabel}</span>
            <span className="btn__text">{children}</span>
        </button>
    );
}
export default Button;

//btn-default

