import React from "react";

import { VisibilityContext, publicApiType } from "react-horizontal-scrolling-menu";
import { ReactComponent as IconLeft } from "../assets/img/Icons/ArrowLeft.svg";
import { ReactComponent as IconRight } from "../assets/img/Icons/ArrowRight.svg";

export function LeftArrow() {
    const visibility = React.useContext<publicApiType>(VisibilityContext);
    const isFirstItemVisible = visibility.useIsVisible("first", true);

    return (
        <ScrollArrow
            disabled={isFirstItemVisible}
            onClick={() => visibility.scrollPrev()}
            btnClassName="button-scroll_left"
        >
            <IconLeft />
        </ScrollArrow>
    );
}

export function RightArrow() {
    const visibility = React.useContext<publicApiType>(VisibilityContext);
    const isLastItemVisible = visibility.useIsVisible("last", true);

    return (
        <ScrollArrow
            disabled={isLastItemVisible}
            onClick={() => visibility.scrollNext()}
            btnClassName="button-scroll_right"
        >
            <IconRight />
        </ScrollArrow>
    );
}

function ScrollArrow({
    children,
    disabled,
    onClick,
    btnClassName,
}: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
    btnClassName: string;
}) {
    return (
        <button
            className={`button-scroll ${btnClassName}`}
            disabled={disabled}
            onClick={onClick}
            style={{
                right: "1%",
                display: disabled ? "none" : "block",
                userSelect: "none",
            }}
        >
            {children}
        </button>
    );
}

