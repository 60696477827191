import React from "react";
import { baseUrl } from "../config";
import EquipmentTableGroup from "./EquipmentTableGroup";

function EquipmentTable({ searchValue, activeTab }) {
    const [arrItems, setArrItems] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const searchValueLowerCase = searchValue.toLowerCase();

    const handleGroupChange = (newState) => {
        setGroups(newState);
    };

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-service/get-equipment-list`)
            .then((res) => res.json())
            .then((arr) => {
                setArrItems(arr.data);
            });
    }, []);

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-group-service/get-equipment-group-list`)
            .then((res) => res.json())
            .then((arr) => {
                const updatedGroups = arr.data.map((group) => ({ ...group, open: true }));
                setGroups(updatedGroups);
            });
    }, []);

    return (
        <>
            <div className="equipment-table">
                <div className="equipment-table-head equipment-table-row">
                    <div className="equipment-table-head__item">
                        <p>Identifier</p>
                    </div>

                    <div>
                        <p>Name</p>
                    </div>
                    <div>
                        <p>Year</p>
                    </div>

                    <div className="equipment-table-head__item">
                        <p>Rating</p>
                    </div>
                </div>

                {groups
                    .filter((group) => group.type === activeTab.name)
                    .filter((group) => {
                        if (!searchValue.trim()) {
                            return true;
                        } else {
                            //todo отфильтровать элементы по searchValue. Если эл-ты есть, группу распечатать
                            const newArr = arrItems
                                .filter((item) => group.name === item.group)
                                .filter((item) => {
                                    return (
                                        item.innerId.toLowerCase().includes(searchValueLowerCase) ||
                                        item.name.toLowerCase().includes(searchValueLowerCase)
                                    );
                                })
                                .map((item) => item);

                            if (newArr.length) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((group) => {
                        return (
                            <EquipmentTableGroup
                                group={group}
                                items={arrItems
                                    .filter((item) => group.name === item.group)
                                    .filter((item) => {
                                        if (!searchValue.trim()) {
                                            return true;
                                        }
                                        return (
                                            item.innerId.toLowerCase().includes(searchValueLowerCase) ||
                                            item.name.toLowerCase().includes(searchValueLowerCase)
                                        );
                                    })
                                    .sort((a, b) => a.rating - b.rating)}
                                groupsChange={handleGroupChange}
                                key={group.id}
                                searchValue={searchValue}
                            />
                        );
                    })}
            </div>
            {groups.length === 0 && (
                <div className="table_empty">
                    <h4 className="title">Let's get started! </h4>
                    <p className="text">Add your first item</p>
                </div>
            )}
        </>
    );
}
export default EquipmentTable;

