import React from "react";
import { useNavigate } from "react-router-dom";

function EquipmentTableRow({ item }) {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/equipment_glossary/${item.id}`);
    };

    return (
        <div className="equipment-table-row equipment-table__link" onClick={handleCardClick}>
            <div className="equipment-table-row__id">{item.innerId}</div>
            <div className="equipment-table-row__name">{item.name}</div>
            <div className="equipment-table-row__year ">{item.year}</div>
            <div className="equipment-table-row__rating">{item.rating}</div>
        </div>
    );
}
export default EquipmentTableRow;

