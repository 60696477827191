import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";

function MainLayout() {
    return (
        <div>
            <Header />

            <main className="wrapper">
                <div className="content">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}

export default MainLayout;

