import { FC, MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface UsersTableRowProps {
    name: string;
    surname: string;
    role: string;
    phone: string;
    link: string;
    status: string;
    id: string;
}

const statusClassMap: { [key: string]: string } = {
    Active: "active",
    Blocked: "blocked",
};

const UsersTableRow: FC<UsersTableRowProps> = ({ name, surname, role, phone, link, status, id }) => {
    const navigate = useNavigate();

    // Используем useCallback для мемоизации функции
    const handleCardClick = useCallback(() => {
        navigate(`/users/${id}`);
    }, [navigate, id]);

    const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
    };

    const statusClass = statusClassMap[status] || "default";

    return (
        <div className="users-table-row users-table__link" onClick={handleCardClick}>
            <div className="users-table-row__name">{`${name} ${surname}`}</div>

            <div className="users-table-row__phone">{phone}</div>
            <div className="users-table-row__link-wrapper">
                <a
                    className="users-table-row__link link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleLinkClick}
                >
                    {link}
                </a>
            </div>

            <div className="users-table-row__status ">
                <div className={`status-bg ${statusClass}`}>{status}</div>
            </div>
            <div className="users-table-row__role">{role}</div>
        </div>
    );
};

export default UsersTableRow;

