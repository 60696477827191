import React from "react";
import { Link } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { ReactComponent as Chevron } from "../assets/img/Icons/Plus.svg";
import Button from "./Button";

function Dropdown({ options = [] }) {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    return (
        <div className={`popover `}>
            <div>
                <Popover
                    isOpen={isPopoverOpen}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    positions={["bottom"]}
                    containerStyle={{ top: "-42px" }}
                    align="end"
                    padding={4}
                    content={
                        <div className="dropdown">
                            {options.map((option) =>
                                option.disabled ? (
                                    <Button
                                        key={option.label}
                                        btnClassName="dropdown__item"
                                        btnLabel={option.label}
                                        disabled={true}
                                    />
                                ) : (
                                    <Link key={option.label} to={option.link}>
                                        <Button btnClassName="dropdown__item" btnLabel={option.label} />
                                    </Link>
                                )
                            )}
                        </div>
                    }
                >
                    <div className={``}>
                        <Button
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            btnLabel="New"
                            btnClassName={`popover__btn btn_default btn-icon ${
                                isPopoverOpen ? "popover__btn_open" : "popover__btn_close"
                            }`}
                        >
                            {<Chevron />}
                        </Button>
                    </div>
                </Popover>
            </div>
        </div>
    );
}
export default Dropdown;

