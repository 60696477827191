import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import NotFound from "./pages/NotFound";
import UserCard from "./pages/UserCard";

import "./scss/app.scss";

import MainLayout from "./layouts/MainLayout";
import EquipmentGlossary from "./pages/EquipmentGlossary";
import Reports from "./pages/Reports";
import Documents from "./pages/Documents";
import AddEquipment from "./pages/AddEquipment";
import EquipmentCard from "./pages/EquipmentCard";

function App() {
    React.useEffect(() => {
        document.title = "Equipment manager";
    }, []);

    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path="" element={<Home />} />
                <Route
                    path="users"
                    element={
                        <Suspense fallback={<div>Идёт загрузка юзеров...</div>}>
                            <Users />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="users/add-user"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <AddUser />
                        </Suspense>
                    }
                />
                <Route
                    path="users/:id"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <UserCard />
                        </Suspense>
                    }
                />

                <Route
                    path="equipment_glossary"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <EquipmentGlossary />
                        </Suspense>
                    }
                ></Route>

                <Route
                    path="equipment_glossary/add-item"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <AddEquipment />
                        </Suspense>
                    }
                />

                <Route
                    path="equipment_glossary/:id"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <EquipmentCard />
                        </Suspense>
                    }
                />

                <Route
                    path="documents"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <Documents />
                        </Suspense>
                    }
                ></Route>

                <Route
                    path="reports"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <Reports />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path="*"
                    element={
                        <Suspense fallback={<div>Идёт загрузка...</div>}>
                            <NotFound />
                        </Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

export default App;

