import enGB from "date-fns/locale/en-GB";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "./Button";

registerLocale("en-GB", enGB);

interface CalendarProps {
    className: string;
    setDateRange: (range: [Date | undefined, Date | undefined]) => void;
}

function Calendar({ className, setDateRange }: CalendarProps) {
    const [dateRange, setLocalDateRange] = React.useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
    const [startDate, endDate] = dateRange;
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClear = () => {
        setLocalDateRange([undefined, undefined]);
        setDateRange([undefined, undefined]);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="calendar">
                <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: [Date | null, Date | null]) => {
                        const newRange: [Date | undefined, Date | undefined] = [
                            update[0] ?? undefined,
                            update[1] ?? undefined,
                        ];
                        setLocalDateRange(newRange);
                        setDateRange(newRange);
                    }}
                    className={startDate ? "react-datepicker__input" : ""}
                    isClearable={false}
                    open={isOpen}
                    onClickOutside={() => setIsOpen(false)}
                    closeOnScroll={true}
                    onFocus={() => setIsOpen(true)}
                    icon={
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`calendar__icon ${startDate ? "calendar__icon--active" : ""}`}
                        >
                            <g id="Icons/Calendar" clipPath="url(#clip0_2638_18551)">
                                <path
                                    id="Vector"
                                    d="M11.9997 2.66602H3.99967C2.52692 2.66602 1.33301 3.85992 1.33301 5.33268V11.9993C1.33301 13.4721 2.52692 14.666 3.99967 14.666H11.9997C13.4724 14.666 14.6663 13.4721 14.6663 11.9993V5.33268C14.6663 3.85992 13.4724 2.66602 11.9997 2.66602Z"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_2"
                                    d="M5.33301 1.33398V4.00065"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_3"
                                    d="M10.667 1.33398V4.00065"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    id="Vector_4"
                                    d="M1.33301 6.66602H14.6663"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2638_18551">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                    placeholderText="7/1/2023 - 7/31/2023"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale="en-GB"
                >
                    {startDate && endDate && (
                        <div className="calendar__buttons-wrapper">
                            <Button
                                btnLabel="Clear"
                                btnClassName="btn_outline btn-small"
                                onClick={handleClear}
                            ></Button>
                            <Button btnLabel="Done" btnClassName="btn_default btn-small" onClick={handleClose} />
                        </div>
                    )}
                </DatePicker>
            </div>
        </>
    );
}

export default Calendar;

