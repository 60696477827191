import React from "react";
import EquipmentTableRow from "./EquipmentTableRow";
import { ReactComponent as Chevron } from "../assets/img/Icons/Chevron.svg";

function EquipmentTableGroup({ items, group, groupsChange }) {
    function toggleGroup(groupId) {
        groupsChange((prev) =>
            prev.map((groupItem) =>
                groupItem.id === groupId
                    ? {
                          ...groupItem,
                          open: !groupItem.open,
                      }
                    : groupItem
            )
        );
    }

    return (
        <div className={`table`}>
            <div className={`${items.length && group.open ? "table-group" : "table-group table-group_close"}`}>
                <div>{group.name}</div>
                {items.length !== 0 && (
                    <div
                        className={`chevron ${group.open ? "chevron_open" : "chevron_close"}`}
                        onClick={() => toggleGroup(group.id)}
                    >
                        <Chevron />
                    </div>
                )}
            </div>
            {items.length !== 0 && (
                <div
                    className={`table-group-items ${group.open ? "table-group-items_open" : "table-group-items_close"}`}
                >
                    {items.map((item) => (
                        <EquipmentTableRow item={item} key={item.id} />
                    ))}
                </div>
            )}
        </div>
    );
}
export default EquipmentTableGroup;

